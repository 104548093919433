<template>
  <app-layout>
    <div class="flex flex-col items-center py-4">
      <div class="w-full px-12">
        <div class="flex flex-row items-center py-4">
          <h1 class="text-lg font-bold">Historial de Ventas por {{ DateFilterTypesDict[filters.filterType] }}</h1>
        </div>
        <div class="w-full flex flex-row justify-center">
          <div
            class="border border-purple-500 text-xl text-center cursor-pointer"
            :class="{ 'bg-purple-500 text-white': filters.filterType === DateFilterTypes.Week }"
            style="min-width: 100px; width: 100px;"
            @click="updateFilters(DateFilterTypes.Week)">
            Semana
          </div>
          <div
            class="border border-purple-500 text-xl text-center cursor-pointer"
            :class="{ 'bg-purple-500 text-white': filters.filterType === DateFilterTypes.Month }"
            style="min-width: 100px; width: 100px;"
            @click="updateFilters(DateFilterTypes.Month)">
            Mes
          </div>
          <div
            class="border border-purple-500 text-xl text-center cursor-pointer"
            :class="{ 'bg-purple-500 text-white': filters.filterType === DateFilterTypes.Year }"
            style="min-width: 100px; width: 100px;"
            @click="updateFilters(DateFilterTypes.Year)">
            Año
          </div>
        </div>
        <content-card class="w-full m-4" :itemsCentered="false">
          <chart :options="sales.options" />
        </content-card>
        <content-card class="w-full m-4" :itemsCentered="false" title="Ordenes">
          <orders-grid :orders="orders" :hasActions="false" referenceCanRedirectOrder @onSorting="handleSorting" />
          <pagination :pagination="pagination" @onPaginationChange="handlePagination" />
        </content-card>
      </div>
    </div>
  </app-layout>
</template>
<style>
.highcharts-axis-labels > text {
  text-transform: capitalize;
}
</style>
<script>
import { Chart } from 'highcharts-vue';
import dates from '@/utils/dates';
import { getLightColor } from '@/utils/colors';
import { DateFilterTypes, DateFilterTypesDict } from '../../../constants';
import OrderService from '@/services/OrderService';

export default {
    name: 'HistoricalSales',
    components: {
      Chart,
      AppLayout: () => import('@/components/layouts/AppLayout.vue'),
      ContentCard: () => import('@/components/molecules/ContentCard.vue'),
      OrdersGrid: () => import('@/views/app/orders/grid/Index.vue'),
      Pagination: () => import('@/components/molecules/Pagination.vue'),
    },
    mounted() {
      this.filters = this.$route.params.filters ?? this.filters;
      this.loadFinancesTotals();
      this.loadFinancesOrders();
    },
    data() {
      return {
        sorting: {
          direction: 'desc',
        },
        pagination: {
          currentPage: 1,
          perPage: 15,
          totalPages: 0,
        },
        filters: {
          filterType: DateFilterTypes.Week,
          startDate: dates.getFirstDayOfWeek(),
          endDate: dates.getLastDayOfWeek(),
        },
        sales: {
          options: {
            credits: {
                enabled: false
            },
            chart: {
                type: "column",
            },
            title: {
                text: null
            },
            xAxis: {
              categories: [],
              crosshair: true,
            },
            yAxis: {
              min: 0,
              title: null,
            },
            tooltip: {
              headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
              pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                  `<td style="padding:0"><b>${this.$store.state.account.currencySymbol} {point.y:,.0f}</b></td></tr>`,
              footerFormat: '</table>',
              shared: true,
              useHTML: true
            },
            plotOptions: {
              column: {
                  pointPadding: 0.2,
                  borderWidth: 0
              }
            },
            series: [],
            colors: [],
          }
        },
        orders: [],
        DateFilterTypes,
        DateFilterTypesDict,
      }
    },
    methods: {
      handleSorting(sorting) {
        const keys = Object.keys(sorting);
        this.sorting.direction = sorting[keys];
        this.loadOrders();
      },
      handlePagination(pagination) {
        this.pagination = pagination;
        this.loadOrders();
      },
      updateFilters(filterType) {
        let startDate = this.filters.startDate;
        let endDate = this.filters.endDate;

        if (filterType === DateFilterTypes.Week) {
          startDate = dates.getFirstDayOfWeek();
          endDate = dates.getLastDayOfWeek();
        }
        if (filterType === DateFilterTypes.Month) {
          startDate = dates.getFirstDayOfYear();
          endDate = dates.getLastDayOfYear();
        }
        if (filterType === DateFilterTypes.Year) {
          startDate = dates.toFormattedString(new Date('01/01/2022'));
          endDate = dates.toFormattedString(new Date('12/31/2024'));
        }
        this.filters = {
          filterType,
          startDate,
          endDate,
        };
        this.loadFinancesTotals();
      },
      async loadFinancesTotals() {
        const data = await OrderService.getFinancesTotals({
          filterType: this.filters.filterType,
          startDate: dates.toFormattedString(this.filters.startDate),
          endDate: dates.toFormattedString(this.filters.endDate),
        });
        this.setSalesValues(data);
      },
      async loadFinancesOrders() {
        const orders = await OrderService.getFinancesOrders({
          startDate: dates.toFormattedString(this.filters.startDate),
          endDate: dates.toFormattedString(this.filters.endDate),
          pageNumber: this.pagination.currentPage,
          pageSize: this.pagination.perPage,
          sortDirection: this.sorting.direction,
        });
        this.setOrders(orders);
      },
      setSalesValues(data) {
        const categories = Object.keys(data);
        const services = [];
        const products = [];
        const total = [];
        const colors = [];

        categories.forEach((category) => {
          services.push(data[category].services);
          products.push(data[category].products);
          total.push(data[category].services + data[category].products);
          colors.push(getLightColor('123456789'));
        });
      
        const series = [
          { name: 'Servicios', data: services },
          { name: 'Productos', data: products },
          { name: 'Total', data: total },
        ];

        this.sales.options.xAxis.categories = categories;
        this.sales.options.colors = colors;
        this.sales.options.series = series;
      },
      setOrders({ data, metadata }) {
        this.orders = data;
        this.pagination = {
          currentPage: metadata.pageNumber,
          perPage: 15,
          totalPages: metadata.pages,
        };
      },
    },
}
</script>